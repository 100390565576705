import '@/assets/css/root-variables.css';
import '@/assets/css/fonts.css';
import '@/assets/css/base.css';
import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import store from './store';
import i18n from './i18n';
import router from './router';

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(VueAxios, axios);

app.mount('#app');

app.config.globalProperties.$http = axios;
