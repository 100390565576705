import { createRouter, createWebHistory } from 'vue-router';
import i18n from '../i18n';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/:locale',
    component: () => import('../views/MainPage.vue'),
    beforeEnter: (to, from, next) => {
      const { locale } = to.params;
      const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(',');

      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE);
      }

      i18n.global.locale = locale;
      document.documentElement.lang = locale;
      document.title = i18n.global.t('title');

      return next();
    },
    children: [
      {
        path: '',
        alias: 'home',
        name: 'home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/About.vue'),
      },
      {
        path: 'music',
        name: 'music',
        component: () => import('../views/Music.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('../views/Contact.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect() {
      return process.env.VUE_APP_I18N_LOCALE;
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
